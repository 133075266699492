/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS (and SCSS, if configured) file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require_tree .
 *= require_self
 */
 @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
 @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Sriracha&display=swap');
 
 @font-face {
  font-family: 'stilted';
  src: url(./fonts/stilted_font.ttf) format('truetype');
}

#container-div {
  background-color: #E5392B;
  border-radius: 15px; 
  padding: 10px;
}

#mobile-container {
  background-color: #E5392B;
}

body {
  background-color: #191B1B;
}

textarea {
  width: 100%;
  height: 130px;
  font-family: 'arial';
}

input {
  width: 100%;
  font-family: 'arial';
}

h1 {
  font-family: 'stilted' !important;
}

.white {
  color: white;
}

.black {
  color:#191B1B;
}

.grey {
  color: grey;
}

.dark-background {
  background-color:#191B1B;
}

.nav-background {
  background-color: #E5392B;
}

.card {
  margin: 20px;
  padding: 8px;
}

.subContainer{
  margin-left: 60px;
}

.center-text {
  text-align: center
}

.music-container{
  width: 50%;
  margin: 0 auto;
}

ul {
  list-style: none;
}

body {
  font-family: 'Bebas Neue', cursive;
}

.album {
  border: 0; 
  width: 100%; 
  height: 274px;
}

.track {
  border: 0; 
  width: 100%; 
  height: 120px;
}

.track-mobile {
  border: 0; 
  width: 100%; 
  height: 42px;
}

.logo {
  font-family: 'stilted' !important;
  font-weight: bold !important;
  color:#191B1B;
  text-decoration: none;
}

.logo:hover {
  color:white;
}

.image {
  height: 80%;
  width: 80%;
  border-radius: 10px;
}

.show_image {
  max-height: 200px;  
  width: auto; 
}

.presale_link {
  color: #E5392B;
}
